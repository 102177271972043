import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import Fade from "react-reveal/Fade";

export default ({ uid, collection_image, title }) => (
  <Link
    to={`/work/${uid}`}
    className="w-1/2 text-center px-3 md:px-8 flex flex-col items-top justify-between mb-8 md:mb-16 group"
  >
    <Fade>
      <div className="flex items-center group-hover:opacity-50">
        <Img
          fluid={collection_image.fluid}
          alt={collection_image.alt}
          className="flex-grow"
        />
      </div>
      <div>
        <h3 className="font-serif text-xl md:text-3xl my-4 leading-tight border-b-2 border-transparent inline-block group-hover:border-black">
          {title.text}
        </h3>
      </div>
    </Fade>
  </Link>
);
